@import 'bootstrap/bootstrap.less';
//@import 'shop.less';

@darkPrimaryColor:   #00796B; //bury, niebieski
@primaryColor:       #6db33f; //jaskrawa zielen
@contentColor: 		 #b0bf6a; //jasna zielen
@bottomColor: 		 #96c472; //zielen ze stopki
@lightPrimaryColor:  #B2DFDB; //jasny niebieski
@accentColor:        #faab53; //pomaranczowy
@secondaryTextColor: #727272; //ciemnoszary
@dividerColor:       #B6B6B6; //jasnoszary
@contentAccentColor: #faab53; //pomarancz
@primaryTextColor:   @primaryColor;
@primary: @primaryTextColor;
@font-family-serif: 'Lobster Two', serif;
@font-family-sanserif: 'Open Sans', sans-serif;
@font-family-sanserif-cond: 'Open Sans Condensed', sans-serif;

@font-size-base: 16px;
@table-border-color: lighten(@accentColor, 20%);

@font-family-base: @font-family-serif;
@body-bg: @primaryTextColor;
@menu-bg: @darkPrimaryColor;
@light-wite: rgba(255, 255, 255, 0.99);
@navbar-color: @primaryTextColor;
@hover: @accentColor;

@link-hover-color: @hover;

@news-body-bg: @primaryColor;

.green {
	color: @primaryColor !important;
}
.orange {
	color: @accentColor !important;
}
.serif {
	font-family: @font-family-serif !important;
}

html {
	margin: 0;
	padding: 0;
	color: #ffffff;
	box-sizing: content-box;
}
body {
	color: #fff;
	background-color: transparent;
	margin: 0;
	padding: 0;
}
	.topbg {
		height: 400px;
	}
	.bhome {
	background: url('../layout/bg/bg1.jpg') no-repeat top center fixed;	
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	}
	.bcontact {
	background: url('../layout/bg/bg-contact.jpg') no-repeat top center;	
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	}
	.bgalleries {
	background: url('../layout/bg/bg-galleries.jpg') no-repeat top center;	
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	}
	.bpage {
	background: url('../layout/bg/bg-uni.jpg') no-repeat top center;	
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	}
	.bguestbook {
		height: 100px;
	}

.content {
	background-color: #fff;
	color: @primaryColor;
	font-family: @font-family-sanserif;
	font-size: 14px;
	h1,h2,h3,h4,h5 {
		color: @accentColor;
		text-shadow: none;
	}
	a {
		color: @accentColor;
		.transition (all .4s);
		&:hover {
		color: @primaryColor;			
		}
	}
}
h1,h2,h3,h4,h5,h6 {
	font-family: @font-family-serif;
	text-shadow: 1px 1px 1px #777777;
	margin: 20px 0;
}

.incontent {
	h1,h2,h3,h4,h5 {
		color: @accentColor;
	}
}

.home {
	margin: 10px;
	padding: 10px;
	h1.main {
		position: fixed;
		display: none;
	}
	h1,h2,h3,h4,h5 {
		text-align: center;
	}
}

.znaczki {
	width: 100%;
	text-align: center;
	margin: 90px 0 80px 0;
	.znaczek {
		margin: 5px;
		border-radius: 54px;
		-webkit-box-shadow: 0px 0px 6px 1px rgba(102,102,102,1);
		-moz-box-shadow: 0px 0px 6px 1px rgba(102,102,102,1);
		box-shadow: 0px 0px 6px 1px rgba(102,102,102,1);
			}
	}

.white {
	background-color: #fff;
	padding: 20px;
	color: @contentColor;
	h1,h2,h3,h4,h5 {
		text-align: center;
		text-shadow: 0 0 0 transparent;
	}
	p {
		line-height: 2em;
		margin: 25px 5px 25px 5px;
	}
	.accent {
		color: @contentAccentColor;
	}
}
.dark {
	color: #fff;
	.accent {
		color: @contentColor;
	}
}
.homecontent {
	.rest {
		background: url('../layout/bg/bg2.jpg') no-repeat top fixed;
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		background-size: cover;
		margin: 0;
		padding: 20px;
		h1,h2,h3,h4,h5 {
			text-align: center;
			text-shadow: 0 0 0 transparent;
		}
		.panels {
			margin: 30px 0 80px 0;
			.panel {
				text-align: center;
				border-radius: 0;
				margin: 8px;
				padding: 9px 9px 0 9px;
				.panel-inn {
				border: 1px dashed @contentColor;
				overflow: hidden;
				height: 430px;
				}
				.panel-heading {
					color: @contentAccentColor;
					font-size: @font-size-large;
					font-weight: bold;
					}
				}
				.panel-body {
					color: @contentColor;
					font-family: @font-family-sanserif;
					font-size: @font-size-base;
				}
			}
			.col-md-3 {
				padding: 4px;
			}
		} //rest

.spec {
	h3 {
		color: @contentAccentColor;
	}
	.panel {
		text-align: center;
		border-radius: 0;
		padding: 9px 9px 0 9px;
		.panel-inn {
		border: 1px dashed @contentColor;
		overflow: hidden;
		height: 600px;
		}
		.panel-heading {
			color: @contentAccentColor;
			font-size: @font-size-large;
			font-weight: bold;
			h3 {
				display: block;
				position: relative;;
				width: 100%;
				background-color: #fff;
				margin: 0;
				padding: 10px; 
				bottom: 100px;
			}
			img {
				margin: 8px 0 15px 0;
			}
		}
		.panel-body {
			margin-top: -70px;
			color: @contentColor;
			font-family: @font-family-sanserif;
			font-size: @font-size-base;
		}
	}

	} //spec

a {
	color: @accentColor;
}

	.glyphicon {
		color: #fff;
		background-color: @contentColor;
		font-size: @font-size-large * 1.7;
		padding: 10px;
		border-radius: 50%;
	    -moz-box-shadow: 1px 2px 5px #999999;
	    -webkit-box-shadow: 1px 2px 5px #999999;
	    box-shadow: 1px 2px 5px #999999;
	    margin: -30px 0 15px 0;
	    .transition (all .4s);
	    &:hover {
	    	background-color: @contentAccentColor;
	    	.rotate(-90deg);
	    }
	}
} //homecontent



	.glyprev {
		background-color: @contentAccentColor;
		color: #fff;
	}

ul, ol {
	list-style-position: inside;
}

h1, h2, h3, h4, h5 {
	text-align: left;
}

.lang {
	width: 100%;
	text-align: right;
	padding: 0 5px 10px 5px;
	margin: 0;
	a {
		background-color: @gray-lighter;
		color: #000;
		margin: 0 2px 0 2px;
		padding: 5px;
		text-transform: uppercase;
		font-family: @font-family-sanserif;
		font-size: @font-size-small*0.8;
		.transition (all .4s);
		&:hover {
			text-decoration: none;
			color: @contentAccentColor;
		}
	}
}
.navbar {
	border: 0;
	border-radius: 0;
	margin: 0;
	color: @primaryTextColor;
	font-family: @font-family-sanserif-cond;
	background-color: #fff;
	-webkit-box-shadow: 0px 0px 2px 1px rgba(102,102,102,1);
	-moz-box-shadow: 0px 0px 2px 1px rgba(102,102,102,1);
	box-shadow: 0px 0px 2px 1px rgba(102,102,102,1);
	//padding: 5px;
	.navbar-brand {
		margin: 0;
		padding: 5px 0 0 25px;
	}
	.navbar-nav li a {
		margin: 0 0 0 0;
		//padding: 10px;
		text-transform: uppercase;
		color: @navbar-color;
		font-size: @font-size-base*1.2;
		.transition (all .4s);
		&:hover {
			color: @hover;
		}	
	}
	.dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0; // remove the gap so it doesn't close
	background-color: #fff;
	.transition (all .4s);
		&:hover {
			-webkit-box-shadow: 0px 0px 1px 0px rgba(102,102,102,1);
			-moz-box-shadow: 0px 0px 1px 0px rgba(102,102,102,1);
			box-shadow: 0px 0px 1px 0px rgba(102,102,102,1);
		}	
	a {
	font-size: @font-size-base;
	color: @navbar-color;
	.transition (all .4s);	
		&:hover {
			background-color: transparent;
			color: @hover;
		}	
	}
 }
ul li ul li {
		&:hover {
			background-color: #f9f9f9;		
		}
	}
} //.navbar

.mainpage {
	margin: 10px;
	padding: 10px;
	border-top: 1px dashed @contentColor;
	border-bottom: 1px dashed @contentColor;
	h1.main {
		position: fixed;
		display: none;
	}
}
.contact_form {
	margin: 10px;
	padding: 10px;
	.control-label {
		text-transform: uppercase;
		font-weight: normal;
		color: @primaryColor;
	}
	input, textarea {
		border: 1px dashed @primaryColor;
		border-radius: 0;
	}
	.btn {
		background-color: @accentColor;
		color: #fff;
		font-family: @font-family-serif;
		font-size: @font-size-large;
		border-radius: 0;
		padding: 10px;
		.transition (all .4s);
		&:hover {
			-webkit-box-shadow: 0px 1px 11px 0px rgba(102,102,102,1);
			-moz-box-shadow: 0px 1px 11px 0px rgba(102,102,102,1);
			box-shadow: 0px 1px 11px 0px rgba(102,102,102,1);
			background-color: @primaryColor;			
		}
	}
}
.greendash td {
	border: 1px dashed @primaryColor;
	padding: 15px;
}
.goomap {
	margin: 0;
	padding: 0;
	height: 400px;
}

.sha1 {
-webkit-box-shadow: inset 40px 51px 5px -47px rgba(0,0,0,0.75);
-moz-box-shadow: inset 40px 51px 5px -47px rgba(0,0,0,0.75);
box-shadow: inset 40px 51px 5px -47px rgba(0,0,0,0.75);
}
.sha2 {
-webkit-box-shadow: inset -40px -51px 5px -47px rgba(122,122,122,0.75);
-moz-box-shadow: inset -40px -51px 5px -47px rgba(122,122,122,0.75);
box-shadow: inset -40px -51px 5px -47px rgba(122,122,122,0.75);
	.glyphicon {
		color: #fff;
		background-color: @contentColor;
		font-size: @font-size-large * 1.7;
		padding: 10px;
		border-radius: 50%;
	    -moz-box-shadow: 1px 2px 5px #999999;
	    -webkit-box-shadow: 1px 2px 5px #999999;
	    box-shadow: 1px 2px 5px #999999;
	    margin: -30px 0 15px 0;
	    .transition (all .4s);
	    &:hover {
	    	background-color: @contentAccentColor;
	    	.rotate(-90deg);
	    }
	}
}

.bottom {
	color: #fff;
	font-family: @font-family-sanserif-cond;
	border-top: 1px dotted @gray-lighter;
	background-color: @bottomColor;
	padding: 10px;
	text-align: center;
	-webkit-box-shadow:inset 0 3px 5px 0 #666666;
	-moz-box-shadow:inset 0 3px 5px 0 #666666;
	box-shadow:inset 0 3px 5px 0 #666666;	
	.botlog {
		border: 1px solid @navbar-color;
		padding: 10px;
		width: 70px;
		height: 30px;
		margin: 0 auto 10px auto;
	}
	a {
		color: @secondaryTextColor;
		.transition (all .4s);
		&:hover {
			text-decoration: none;
			color: darken(@secondaryTextColor,30%);
		}
	}
}

.bottom_log {
	float: left;
	width: 200px;
	text-align: left;
	a 
	{
	font-size: @font-size-small;
	padding: 5px;
	} 
}


.bguni {
	background-color: rgba(255, 255, 255, 0.96);;
	padding: 20px;
	border-radius: @border-radius-large;
}

.news-out {
	padding: 10px; 
}
.page-out {
	padding: 0; 
	text-align: center;
}
.news-out-one {
	padding: 0; 
	text-align: center;
}
.news-out-white {
	background-color: @navbar-color;
	padding: 10px;
}
.news {
	.panel {
		background-color: transparent;
		.panel-body {
			border-bottom: 1px solid @dividerColor;
			.transition (all .4s);
			&:hover {
				border-bottom: 1px solid @darkPrimaryColor;
			}
		}
		.cat {
			font-size: @font-size-small;
			}
	}
	.news_list {
		width: 100%;
		text-align: center;
		&:hover {
			text-decoration: none !important;
			// color: inherit;
		}
	}
	.news-panel {
		width: 254px;
		display: inline-block;
		text-align: center;
		margin: 3px;
		// color: @body-bg;
		.date {
			// color: @exbg1;
			font-style: italic;
		}
		h2 {
			text-transform: none;
		}
		.lead {
			font-size: @font-size-base;
			overflow: hidden;
		}
	}
	.news-panel-home {
		display: inline-block;
		text-align: center;
		margin: 30px;
		.date {
			font-style: italic;
		}
		h2 {
			text-transform: none;
		}
		.lead {
			font-size: @font-size-base;
			overflow: hidden;
		}
	}
	.min {
		height: 250px;
	}
	.more {
		color: @hover;
		font-style: italic;
	}
	.logo-small {
		margin-top: 20px;
	}
}

.no_cat {
	width: 100%;
	font-size: @font-size-small;
}
.no_date {
	color: @lightPrimaryColor;
	font-style: italic;
}
.no_lead {
	text-align: left;
	overflow: hidden;
	border-bottom: 1px dotted @gray-lighter;
}
.no_content, .page_lead {
	overflow: hidden;
	// border-bottom: dashed 1px @gray-lighter;
	// padding-bottom: 10px;
	// margin-bottom: 10px;
	text-align: left;
	font-family: @font-family-sanserif;	
	color: @primaryColor;
	p, div, td {	
	line-height: 1.4em !important;
	}
	a {
		color: darken(@primaryColor,20%);
		.transition (all .4s);
		&:hover {
			color: @accentColor;
			text-decoration: none;
		}
	}
	.media {
		margin: 10px;
		border: 1px solid @dividerColor;
	}	
}
.galeria {
	text-align: center;
	.fotka {
		width: 126px;
		display: inline-block;
	}
	.fotka_gal {
		width: 156px;
		display: inline-block;
	}
	.thumbnail {
		img {
		border: 1px solid lighten(@accentColor, 20%);
		padding: 2px;
		// background-color: transparent;
		margin-bottom: 5px;
		.transition (all .4s);
		&:hover {
			border: 1px solid @accentColor;
			-webkit-box-shadow: 0px 0px 4px 0px rgba(102,102,102,1);
			-moz-box-shadow: 0px 0px 4px 0px rgba(102,102,102,1);
			box-shadow: 0px 0px 4px 0px rgba(102,102,102,1);
			}
		}
	}
	.category {
		font-size: @font-size-small;
		padding: 2px;
		margin: -10px 0 0 0;
		text-align: right;
	}
}
#image-gallery-image {
	margin: 5px auto;
}
.gal_cat {
	display: block;
	margin: 0 auto;
	text-align: center;
	.panel {
		background-color: transparent;
		margin: 8px;
		min-height: 271px;
		max-width: 498px;
		border-radius: 0;
		.panel-body {
			border: none;
			.transition (all .4s);
			&:hover {
				// background-color: darken(@navbar-color, 10%)
			}
		}
		.cat {
			font-size: @font-size-small;
			}
		.gal_name {
			text-align: right;
			font-size: @font-size-large;
			margin: 200px -15px 0 0;
			padding: 10px;
			bottom: 10px;
			color: #fff;
			background-color: rgba(255, 255, 255, 0.5);
			.transition (all .4s);
			&:hover {
				color: @accentColor;
				background-color: rgba(255, 255, 255, 0.9);
			}
		}
	}
	.gal-panel {
		h2 {
			text-transform: none;
		}
		.lead {
			font-size: @font-size-base;
			overflow: hidden;
		}
	}

	//tla dla wejsc do galerii
	.gal2 {
		background: url('../img/upload/galleries/g2.jpg') no-repeat;	
	}
	.gal4 {
		background: url('../img/upload/galleries/g4.jpg') no-repeat;	
	}
	.gal3 {
		background: url('../img/upload/galleries/g3.jpg') no-repeat;	
	}
	.gal0 {
		background: url('../img/upload/galleries/g0.jpg') no-repeat;	
	}
}

.contact {
	.address {
		text-transform: none;
		font-size: @font-size-large;
		text-align: left;
		overflow: hidden;
	}
}
.sprawdzacz {
	display: none;
}

.alert {
	margin-top: 50px;
}

.augustyn {
	width: 99%;
	text-align: right;
	padding: 10px;
	bottom: 0;
}

.modal-content {
	width: 95%;
	background-color: @light-wite;
	color: @primaryColor;	
	margin: 60px auto 0 auto;
}
#image-gallery-caption {
	text-align: center;
	font-style: italic;
	color: @primary;
}
#show-next-image {
	float: right;
}


.red {
	border: 1px solid red;
}

.dlogo {
	margin: 10px;
	padding: 50px;
}

//tabelki w tresci

.terminy, .cennik {
	font-size: @font-size-small;
	width: 100%;
	padding: 0 15px;
	.nag3 {
		text-align: center;
		font-weight: bold;
		color: @accentColor;
		vertical-align: middle;
		padding: 1px;
	}
	.me4 {
		vertical-align: middle;
		text-align: center;
	}
	.cenn {
		vertical-align: middle;
	}
	.me {
		vertical-align: middle;
		text-align: center;
	}
	.botline {
	 	border-bottom: 1px dashed lighten(@accentColor, 25%);
	}
	.intab {
	 	width: 100%;
	 	td {
		 	padding: 5px;
		 }
	}
	.nrpok {
		width: 10%;
		text-align: center;
	}
}
	.dl-horizontal {
			color: @contentColor;
		dd {
			font-size: @font-size-small;
			font-weight: normal;
		}
	}
.tooltip {
	font-family: @font-family-sanserif;
	font-size: @font-size-small*.8;
}
span.tooltip-active:hover {
	cursor: default;
}

.zobacz {
	background-color: @accentColor !important;
	color: #fff !important;
	font-family: @font-family-serif;
	font-size: @font-size-large;
	border-radius: 0;
	padding: 10px;
	.transition (all .4s);
	&:hover {
		-webkit-box-shadow: 0px 1px 11px 0px rgba(102,102,102,1);
		-moz-box-shadow: 0px 1px 11px 0px rgba(102,102,102,1);
		box-shadow: 0px 1px 11px 0px rgba(102,102,102,1);
		background-color: @primaryColor;
		text-decoration: none;			
		}
	}

.carousel-indicators li {
	color: @accentColor;
	border: 1px solid @accentColor;
}

.guestbook_bg {
	background: url('../layout/bg/bg2.jpg') no-repeat top center fixed;	
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;	
}
.gb_container {
	padding: 30px 0 30px 0;
	.entry {
		text-align: center;
		color: @contentColor;
		float: left;
		.entry_out {
			margin: 8px 0 8px 0;
			padding: 8px;
			background-color: #fff;
		}
		.entry_in {
			width: 100%;
			height: 100%;
			border: 1px dashed @primaryColor;
			margin: 6px auto;
			padding: 5px;
		}
		.fota {
			margin: 5px 5px 10px 5px;
			img {
				width: 100%;
			}
		}
		.data {
			font-family: @font-family-sanserif;
			font-size: @font-size-small * .9;
			font-style: italic;
			font-weight: bold;
		}
		.who {
			color: @accentColor;
			font-size: @font-size-large;
			margin-top: 10px;
		}
		.from {
			color: @accentColor;
			font-size: @font-size-large;
			margin-bottom: 10px;			
		}
		.gb_cont {
			font-family: @font-family-sanserif;
			font-size: @font-size-small *.9;
			font-style: italic;
			text-align: left;
		}
	}
	.gb_admin {
		width: 100%;
		font-family: @font-family-sanserif-cond;
		font-size: @font-size-small;
		text-align: left;
		.glyphicon {
			float: right;
			font-size: @font-size-small;
			color: @primaryColor;
			&:hover {
				color: @accentColor;
			}
		}
	}
}

.pagination-sm {
	font-family: @font-family-sanserif;
	li a {
		color: @bottomColor;		
	}
	li:last-child > a {
		border-radius: 0;
		color: @bottomColor;		
	}
	.active > a , .active > a:hover {
		background-color: @accentColor;
		border: 1px solid @accentColor;
	}
}

.gb_entry {
	label {
		color: @primaryColor;
		font-weight: normal;

	}
}

.guestgalform {
	  font-family: @font-family-sanserif;
				@import 'guestgalform.less';
	input {
		border-radius: 0;
	}
	.legend {
		clear: both;
		font-size: @font-size-small;
		color: @primaryColor;
		margin: 10px 20px;
	}
}
.gugal {
	font-family: @font-family-sanserif-cond;
	display: block;
	color: inherit;
	xborder: 1px solid transparent;
	.transition (all .6s);
	&:hover {
		text-decoration: none;
		color: inherit;
		border: 1px solid @accentColor;
		-webkit-box-shadow: 0px 0px 4px 0px rgba(102,102,102,1);
		-moz-box-shadow: 0px 0px 4px 0px rgba(102,102,102,1);
		box-shadow: 0px 0px 4px 0px rgba(102,102,102,1);
	}
}
//galerie gosci
.master {
	font-family: @font-family-sanserif-cond;
	font-size: @font-size-large;
	position: absolute;
	width: 80%;
	margin: 0;
	padding: 5px;
	top: 45px;
	color: red;
	background-color: rgba(255, 255, 255, 0.5);
}
.konkurs {
	font-family: @font-family-sanserif;
	font-size: @font-size-small;
	padding: 5px;
	border-style: none dashed dashed dashed;
	border-color: @primaryColor;
	border-width: 1px;
	input {
		width: 30px;
	}
}
.niepotwierdzona {
	color: red;
	text-align: center;
	font-size: @font-size-large;
}

#splywy {
	font-size: @font-size-small*.9;
	.lp {
		font-size: @font-size-small*.7;
		text-align: center;
		color: @contentColor;
	}
}

