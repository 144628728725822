.fotoform{

}

.fotoform>div{
  position: relative;
}

.element-file {
      margin: 4px 0;
}
.fotoform .element-file label{
  position: relative;
}
.fotoform .element-file .title{
  position: static;
}
.fotoform .element-file .button {
  z-index: 1;
  position: absolute;
  top: 3px;
  right: 3px;
  bottom: 3px;
  float: none;
  padding: .6% 1em 0 1em;
  text-align: center;
  color: #fff;
  opacity: 0.8;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;

}
.fotoform .element-file .button:hover {
  box-shadow: none;
  opacity: 1;
}
.fotoform .element-file .file_input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 0;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
  width: 100%;
}
.fotoform .element-file .file_text{
  overflow: hidden;
  cursor: pointer;
  bottom: 0;
}
* html .fotoform .element-file .file_input,
*+html .fotoform .element-file .file_input{
  position: relative;
  left: auto;
  right: auto;
  top: auto;
  bottom: auto;
  width: auto;
  opacity: 1;
  filter: alpha(opacity=100);
}
* html .fotoform .element-file .button,
*+html .fotoform .element-file .button{
  display: none;
}
* html .fotoform .element-file label,
*+html .fotoform .element-file label,
* html .fotoform .element-file .file_text,
*+html .fotoform .element-file .file_text{
  border: none;
}
* html .fotoform .element-file .title,
*+html .fotoform .element-file .title{
  display: block;
}


.fotoform .element-file .file_text,
.fotoform  select,
.fotoform button{
  border: solid 1px #cccccc;
  border-radius: 0;
  background: #fff;
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s;
  resize: none;

  outline: medium none;
  padding: 0.4em;
  -o-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 1.1em;
}

.fotoform textarea:hover,
.fotoform .element-file .file_text:hover,
.fotoform  select:hover{
  border-color: #F5B093;
}
.fotoform textarea:focus,
.fotoform .element-file .file_text:active,
.fotoform  select:focus{
  border-color: @accentColor;
}
.fotoform .element-file .button{
  background-color: @accentColor;
}
